import React from "react";

const Blockblack = () => {
  return (
    <div className="mx-auto w-full flex flex-col md:flex-row bg-mediumGray">
      {/* Text Section */}
      <div className="md:w-1/2 flex items-center p-8 order-3 md:order-2 fades-in">
        <div className="w-full text-center md:text-left">
          <div className="flex flex-col md:flex-row justify-center md:justify-start items-center md:items-end">
            <h2 className="text-5xl font-bold mb-4 text-center md:text-left text-white">Dimitri van Iersel</h2>
            {/* Icon below the text on small screens, next to the text on medium screens and up */}
            <img
              src={process.env.PUBLIC_URL + '/cio.png'} // Replace 'your-icon.png' with the actual icon filename
              alt="Icon"
              className="h-8 w-auto mt-4 md:mt-0 md:ml-4 mb-5" // Adjust the size as needed, add margin-top on small screens, and margin-left on medium screens
            />
          </div>
          <p className="text-whitesub mb-1 lg:mb-6">
           Als Chief Information Officer (CIO) van Lizz ben ik voortdurend gefocust op innovatie en alles wat gerelateerd is aan de softwarematige aspecten van onze projecten en producten. In het domein van app-ontwikkeling en andere softwareontwikkelingsactiviteiten, zorg ik ervoor dat alle processen vlekkeloos verlopen en dat er uitstekende resultaten worden behaald.
          </p>
          <p className="text-whitesub  mb-4 lg:mb-1">
            <span className="font-bold">Email:</span><span> dimitrivaniersel@lizzinnovations.com</span>
          </p>
          <p className="text-whitesub  mb-0 lg:mb-4">
            <span className="font-bold">Number:</span><span> dimitrivaniersel@lizzinnovations.com</span> {/* Replace with the actual number */}
          </p>
        </div>
      </div>

      {/* Placeholder for other content */}
      <div className="md:w-16 order-2 md:order-1 justify-start md:mr-0 mb-0">
        {/* Additional content can go here */}
      </div>

      {/* Image Section */}
      <div className="md:w-1/2 order-1 md:order-3 justify-start ml-0 mb-0 md:mr-0 mr-0">
        <div className="w-full h-full flex justify-center items-center overflow-hidden">
          <img
            src={process.env.PUBLIC_URL + '/dimiblok.png'}
            alt="Placeholder"
            className="max-w-full h-auto slide-fade-in"
          />
        </div>
      </div>
    </div>
  );
}

export default Blockblack;