import React, { useState, useEffect } from 'react';
import useIntersectionObserver from './useIntersectionObserver';
import './App.css';
import './teamsection.css';
import './navbar.css';
import { Link } from 'react-router-dom';
import ContactPage from './ContactPage';
import Productpage from './Productpage';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function TeamSection() {
    const [ref, isVisible] = useIntersectionObserver();

  const teamMembers = [
   { name: 'Dimitri van Iersel', title: 'CIO', image: 'dimitri.png', text: 'Ik ben Dimitri, hoofd van de software-afdeling, gedreven om met expertise innovatieve en efficiënte technologische oplossingen te leveren.' },
    { name: 'Max Gootzen', title: 'CTO', image: 'max.png', text: 'Ik ben Max Gootzen, Ik ben verantwoordelijk voor de product ontwikkeling bij Lizz Innovations. Ik word gedreven door innovatie en creativiteit.' },
    { name: 'Omar Sokar', title: 'CFO', image: 'omar.png', text: 'Ik ben Omar Sokar, mijn passie voor financiën drijft mij om de administratie te optimaliseren, zodat ons bedrijf soepel kan opereren.'},
    { name: 'Stefan Clercx', title: 'CEO', image: 'stefan.png', text: 'Ik ben Stefan Clercx. Mijn verantwoordelijkheid ligt bij het leiden van het team. Ik word gedreven door technologie en leiderschap.' },
    { name: 'Lucas Bogaart', title: 'CMO', image: 'lucas.png', text: 'Als marketingervarene houd ik me graag bezig met het marketen van technologische innovaties' }
  ];


  const renderBlock = (member) => (
    <div className="bg-mediumGray3 rounded-2xl overflow-hidden" style={{ width: '185px', height: '350px' }}>
      <div className="bg-customGray2 p-4 text-center">
        <img src={process.env.PUBLIC_URL + '/' + member.image} alt={`${member.name}`} className="mx-auto rounded-full mb-2 w-28 mt-4" />
        <h3 className="font-semibold text-customGray">{member.name}</h3>
        <p className="text-sm text-gray-500">{member.title}</p>
      </div>
      <div className="p-4">
        <p className="text-xs">
          {member.text}
        </p>
      </div>
    </div>
  );

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleScroll = (e) => {
    const container = e.target;
    const scrollPerSlide = container.scrollWidth / teamMembers.length;
    const currentSlide = Math.round(container.scrollLeft / scrollPerSlide);
    setCurrentSlide(currentSlide);
  };



  return (
       
    <div className="bg-mediumGray text-white p-16 overflow-hidden font-interfont">
      <h2 className="text-3xl font-bold mb-4 text-center">People behind the product</h2>
      <p className="mb-16 text-center">
Het team van Lizz Innovations is vijf man sterk. Elk lid heeft zijn eigen, unieke kwaliteiten waardoor het team flexibiliteit en veerkracht creëert. 
      </p>
       <div ref={ref} className={`fade-in ${isVisible ? 'visible' : ''}`}>
<div className="flex overflow-x-auto hide-scrollbar md:justify-center items-center md:flex-wrap" onScroll={handleScroll}>
        {teamMembers.map((member, index) => (
          <div key={index} className="flex-shrink-0 w-48 mr-4 mb-6">
            {renderBlock(member)}
          </div>
        ))}
      </div>
      <div className="flex justify-center space-x-2 mt-4 md:hidden">
        {teamMembers.map((_, index) => (
          <div
            key={index}
            className={`h-2 w-2 rounded-full ${index === currentSlide ? 'bg-white' : 'bg-gray-500'}`}
          ></div>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        <Link className="bg-customBlue text-customGray py-3 px-8 rounded-2xl sm:text-2xl mt-6 md:self-auto items-center text-2xl hover:bg-sky-700" to="/about">More About</Link>
      </div>
    </div>
    </div>
  );
}

export default TeamSection;