import React from 'react';

const Bjgrad = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-500 ">
      {/* Content van je pagina hier */}
      <div className="w-full">
      <img src="/scherm2.png" alt="Team" className="w-full h-auto" />
    </div>
        <div className=" inset-0 flex flex-col justify-center items-center pt-10">
          <h1 className="text-5xl text-darkGray font-bold  font-interfont">Lizzard One</h1>
                    <h1 className="text-2xl text-darkGray mb-4  font-interfont">lizz Innovation</h1>
          <h2 className="text-lg text-darkGray mt-2 mb-6 font-interfont w-6/12">Ons nieuwste product op het gebied van innovatie en techniek. Mis het niet, meld u alvast aan om updates te ontvangen!</h2>
        </div>
                    <div className="flex justify-center">
<button onClick={() => window.location = 'mailto:info@lizzinnovations.com'}className="bg-customBlue text-customGray py-3 px-8 rounded-2xl self-center md:self-auto text-2xl flex items-center hover:bg-sky-700">
  Meld aan
</button>
            </div>
      <div className="w-full">
      <img src="/outscherm.png" alt="Team" className="w-full h-auto" />
    </div>
            
      {/* Andere componenten en elementen */}
    </div>
  );
};

export default Bjgrad;