import React from 'react';

function Teampic() {
  return (
    <div className="w-full">
      <img src="/groepcut.png" alt="Team" className="w-full h-auto" />
    </div>
  );
}

export default Teampic;