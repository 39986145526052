import React, { useEffect } from 'react';
import './App.css';
import useIntersectionObserver from './useIntersectionObserver';

function Eco() {
    const [ref, isVisible] = useIntersectionObserver();

    useEffect(() => {
        if (isVisible) {
            const elements = document.querySelectorAll('.slide-fade-in');
            elements.forEach((element) => {
                element.classList.add('slide-in-animation');
            });
        }
    }, [isVisible]);



  return (
 <div className="font-interfont bg-mediumGray3 pb-8 ">
<div className='container text-center md:text-left text-5xl mb-4 pt-8 mx-auto flex flex-col md:flex-row items-center justify-center md:justify-start'>
    <h1 className='pl-7 pt-6 text-white mb-4 md:mb-0'>Recycled & Sustainable</h1>
    <img
        src={process.env.PUBLIC_URL + '/bladicon.png'}
        alt="Placeholder"
        className="ml-0 md:ml-2 text-5xl w-24"
    />
</div>
      <div className="container mx-auto my-16 flex flex-col md:flex-row md:space-x-8">
        <div ref={ref} className={`md:w-1/2 p-8 mb-8 md:mb-0 flex flex-col md:text-left order-2 md:order-1 lg:fade-in ${isVisible ? 'visible' : ''}`}>
          <div className="flex-grow">
            <h2 className="text-3xl font-bold mb-4 text-center md:text-left text-white">Energie Efficiënt </h2>
            <p className="text-gray-300 mb-4 text-center md:text-left">
Lizz Innovations streeft ernaar om duurzame en betaalbare technologische oplossingen te kunnen leveren en voor iedereen toegankelijk te maken. Daarom zijn wij bezig met de ontwikkeling van innovatieve energie efficiënte technologieën.  

Door deze energiebesparende manier zijn onze technologische innovaties van de toekomst voor zowel de korte- als de langetermijn betaalbaar én duurzaam voor iedereen die er gebruik van maakt.  
            </p>
          </div>

        </div>
     <div className="md:w-1/2 order-1 md:order-2 flex justify-center md:justify-start md:mr-0">
                    <div className="w-full max-h-96 overflow-hidden">
                        <img
                            src={process.env.PUBLIC_URL + '/greens.png'}
                            alt="Placeholder"
                            className={`w-full h-full object-cover lg:fade-in ${isVisible ? 'visible' : ''}`}
                        />
                    </div>
      </div>
      </div>
            <div className="container mx-auto my-16 flex flex-col md:flex-row md:space-x-8">
        <div className={`md:w-1/2 p-8 mb-8 md:mb-0 flex flex-col md:text-left order-2 md:order-2 lg:fade-in ${isVisible ? 'visible' : ''}`}>
          <div className="flex-grow">
            <h2 className="text-3xl font-bold mb-4 text-center md:text-left text-white">Materials</h2>
            <p className="text-gray-300 mb-4 text-center md:text-left">
Bij Lizz Innovations zijn de volgende generaties belangrijk bij onze creatie van toekomstgerichte innovatieve technologie en software. Wij zetten onze visie op een toekomst met grensverleggende technologie in diverse sectoren. Om deze echter waar te kunnen maken, is de eerste stap om een welvarende toekomst te verzekeren. 

Onze toekomstgerichte technologie wordt grotendeels samengesteld met aluminium. Onze producten zijn sterk, licht en 100% recyclebaar. Hierdoor zijn onze producten gemakkelijk te tillen, moeilijker te breken dan gemiddelde elektronica, en niet schadelijk voor de omgeving. 
            </p>
          </div>

        </div>
      <div className="md:w-1/2 order-1 md:order-1 flex justify-center md:justify-start md:mr-0">
                    <div className="w-full max-h-96 overflow-hidden">
                        <img
                            src={process.env.PUBLIC_URL + '/allumpaatje.png'}
                            alt="Placeholder"
                            className={`w-full h-full object-cover lg:fade-in ${isVisible ? 'visible' : ''}`}
                        />
                    </div>
      </div>
      </div>
    </div>
    
  );
}

export default Eco;