import React from 'react';
import FAQSection from './FAQSection';
import BlogList from './BlogList';
import FAQAccordion from './FAQAccordion';
import Navblock from './Navblock';
import ArticleSection from './ArticleSection'; // Adjust the path as per your file structure
import Blogbutton from './Blogbutton';
const articles = [
    {
        thumbnail: process.env.PUBLIC_URL + '/P1000504.JPG',
        title: 'hoe maak je interieur',
        subtext: 'This is some description of the article...',
        author: 'John Doe',
        authorImage: process.env.PUBLIC_URL + '/max.png',
        date: 'Jan 1, 2024'
    },
        {
        thumbnail: process.env.PUBLIC_URL + '/P1000504.JPG',
        title: 'Article Title 1',
        subtext: 'This is some description of the article...',
        author: 'John Doe',
        authorImage: process.env.PUBLIC_URL + '/max.png',
        date: 'Jan 1, 2024'
    },
        {
        thumbnail: process.env.PUBLIC_URL + '/P1000504.JPG',
        title: 'Article Title 1',
        subtext: 'This is some description of the article...',
        author: 'John Doe',
        authorImage: process.env.PUBLIC_URL + '/max.png',
        date: 'Jan 1, 2024'
    },
    // ... more articles
];
const BlogPage = () => {
    return (

        <div>
                <Navblock />
                           <FAQAccordion />

            <Blogbutton />
        </div>
    );
};

export default BlogPage;