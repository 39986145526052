import React from 'react';
import BlogList from './BlogList';
import ArticleSection2 from './ArticleSection2';
import Blogpagina from './Blogpagina';
import Navblock from './Navblock';
import Blogbutton from './Blogbutton';
import Articletop from './Articletop';
import ArticleSection from './ArticleSection'; // Adjust the path as per your file structure
import Faqbutton from './Faqbutton';
import FullArticle2Page from './FullArticle2Page';
import FullArticle3Page from './FullArticle3Page';
const articles = [
    {
        thumbnail: process.env.PUBLIC_URL + '/P1000504.JPG',
        title: 'Eindhoven: design and technology [soon]',
        subtext: 'This article will be out soon',
        author: 'Stefan Clercx',
        authorImage: process.env.PUBLIC_URL + '/stefan.png',
        date: '2024',
        pagePath: 'Bloggie'
    },
        {
        thumbnail: process.env.PUBLIC_URL + '/banner_article3.jpg',
        title: 'A new groundbreaking product',
        subtext: 'This is some description of the article...',
        author: 'Dimitri van Iersel',
        authorImage: process.env.PUBLIC_URL + '/dimitri.png',
        date: 'Jan 10, 2024',
        pagePath: 'FullArticle3'
    },
        {
        thumbnail: process.env.PUBLIC_URL + '/banner_article2.jpg',
        title: '[Hello world]',
        subtext: 'Welkom op onze website. Hier kan uw al he...',
        author: 'Dimitri van Iersel',
        authorImage: process.env.PUBLIC_URL + '/dimitri.png',
        date: 'Jan 14, 2024',
        pagePath: 'FullArticle2'
    },
    // ... more articles
];

const articles2 = [
    {
        thumbnail: process.env.PUBLIC_URL + '/banner_article1.jpg',
        title: 'Lizz Innovations: Het technologiebedrijf van de toekomst',
        subtext: 'Technologiebedijf Lizz Innovations trekt momenteel aan het voortouw op het gebied van slimme decoratie. Het team van vijf gemotiveerder studenten zijn momenteel full-time bezig met het verleg....',
        author: 'Lucas Bogaart',
        authorImage: process.env.PUBLIC_URL + '/lucas.png',
        date: 'Jan 15, 2024'
    },
    // ... more articles
];

const BloggiePage = () => {
    return (

        <div>
                <Navblock />
            <Blogpagina />
            <Articletop articles={articles2} />
                        <ArticleSection2 articles={articles} />
                                    <Faqbutton />
                                                
        </div>
    );
};

export default BloggiePage;