import React from 'react';
import { Link } from 'react-router-dom';

const ArticleSection2 = ({ articles }) => {
    return (
        <div className="max-w-6xl mx-auto p-6 border-t border-gray-300">
            <div className="flex flex-col sm:flex-row justify-between flex-wrap">
                {articles.map((article, index) => (
                    <div key={index} className="flex-1 mx-2 mb-4 rounded-2xl p-4 max-w-sm text-mediumGray">
                        {/* Clickable Thumbnail */}
                        <Link to={`/${article.pagePath}`} className="block">
                            <img 
                                src={article.thumbnail} 
                                alt="Thumbnail" 
                                className="rounded-2xl w-full h-48 object-cover"
                            />
                        </Link>

                        {/* Clickable Title */}
                        <Link to={`/${article.pagePath}`} className="block hover:text-blue-600">
                            <h2 className="text-2xl mt-6 font-bold mt-2 pl-0 text-left">{article.title}</h2>
                        </Link>
                        <p className="text-mediumGray pb-2 pt-2 pl-0 text-left">{article.subtext}</p>
                        <div className="flex items-center mt-2">
                            <img 
                                src={article.authorImage} 
                                alt="Author"
                                className="rounded-full w-8 h-8 mr-2"
                            />
                            <span className="mr-2">{article.author}</span>
                            <span className="text-gray-500">{article.date}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ArticleSection2;