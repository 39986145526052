import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router
import BloggiePage from './BloggiePage';
import FAQSection from './FAQSection';

const Blogpagina = () => {
    // Example blog posts data
    const posts = [
        { id: 1, title: 'News & Blog', summary: 'Hier vind je het laatste nieuws, evenals interessante en relevante blogs.' },
        // ... other posts
    ];

    return (
        <div className='mt-0 pt-10   text-black'>
            {posts.map(post => (
                <Link key={post.id} to={`/Bloggie`}>
                    <div className="p-4">
                        <h2 className="text-4xl font-bold mb-4">{post.title}</h2>
                        <p>{post.summary}</p>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default Blogpagina;