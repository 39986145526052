import React, { useState, useEffect } from 'react';
import './slideshow.css';
import useIntersectionObserver from './useIntersectionObserver';
import './App.css';

function Slideshowtwo() {
  const [isAnimated, setIsAnimated] = useState(false);

  const animateSlideshow = () => {
    setIsAnimated(true);
    window.removeEventListener('scroll', animateSlideshow);
  };

  return (
    <div className={`slideshow ${isAnimated ? 'animate' : ''} bg-mediumGray`}>
      <div className={`slide active`}>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="absolute inset-0 flex flex-col justify-center items-center">
          <h1 className="text-5xl text-white font-bold  font-interfont">About</h1>
          <h2 className="text-lg text-white mt-2 font-interfont w-6/12">Het innovatieve technologie- en softwarebedrijf van de toekomst is ontstaan door de ambitie van vijf jonge ondernemers. Op basis van ieders kwaliteiten vormen deze vijf een daadkrachtig, gemotiveerd en flexibel team. Met de start van Lizz Innovations verlegt dit team de grenzen op het gebied van technologische innovatie in verschillende sectoren.</h2>
        </div>
      </div>
    </div>
  );
}

export default Slideshowtwo;