import React from 'react';

function ContactPage() {
  return (
    <div>
      <h1>About Page</h1>
      <p>This is the about page content.</p>
    </div>
  );
}

export default ContactPage;