import React from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useIntersectionObserver from './useIntersectionObserver';
import {faFacebook, faLinkedin, faTiktok, faInstagram, faYoutube, faPinterest, faWindows, faLinux, faApple, faAndroid} from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';
import ContactPage from './ContactPage';
import Productpage from './Productpage';
import { NavLink } from 'react-router-dom';

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>


function LizzardoneText() {
    const [ref, isVisible] = useIntersectionObserver();

  return (
    


    
    <div className=" bg-customGray4 text-white pt-16 pb-16 font-interfont">

      

      <div className="container mx-auto my-16 flex flex-col md:flex-row md:space-x-8">
        <div ref={ref} className={`md:w-1/2 p-8 mb-8 md:mb-0 flex flex-col md:text-left order-2 md:order-1 fade-in ${isVisible ? 'visible' : ''}`}>
          <div className="flex-grow">
            <h2 className="text-3xl font-bold mb-4 text-center md:text-left">Lizzard One</h2>
            <p className="text-gray-300 mb-4 text-center md:text-left">
Ons eerste product, Codenaam Lizzard One, is momenteel nog volop in ontwikkeling. Met dit product zetten wij de eerste stap in grensverleggende innovatie op het gebied van techniek voor iedere woon- en werkruimte.  

Houd onze website en sociale media in de gaten voor meer informatie.  
            </p>
          </div>
          <div className="flex flex-col lg:flex-row md:items-start sm:items-center mt-auto">
            <div className="flex justify-center space-x-4 md:my-2 md:mb-6 text-4xl my-6">
<a href="https//facebook.com"> <FontAwesomeIcon icon={faWindows} /></a> 
<a href="https//facebook.com"> <FontAwesomeIcon icon={faAndroid} /></a> 
<a href="https//facebook.com"> <FontAwesomeIcon icon={faApple} /></a> 
<a href="https//facebook.com"> <FontAwesomeIcon icon={faLinux} /></a> 
            </div>
            <div className="flex justify-center lg:ml-4">
<Link className="bg-customBlue text-customGray py-3 px-8 rounded-2xl self-auto text-2xl flex hover:bg-sky-700" to="/product">
 Learn more
</Link>
            </div>
          </div>
        </div>
      <div className="md:w-1/2 order-1 md:order-2 flex justify-center md:justify-start md:mr-0">
        <div className="w-full max-h-96 overflow-hidden">
          <img
            src={process.env.PUBLIC_URL + '/soon.gif'}
            alt="Placeholder"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      </div>

    </div>

  );
}

export default LizzardoneText;