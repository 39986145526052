import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Navblock from './Navblock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faTiktok, faInstagram, faWhatsapp, faPinterest, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCopy, faShareAlt } from '@fortawesome/free-solid-svg-icons';


const FullArticle2Page = () => {
        const [showMobileShare, setShowMobileShare] = useState(false);
    const thumbnailUrl = process.env.PUBLIC_URL + '/banner_article2.jpg'; 
    const authorImageUrl = process.env.PUBLIC_URL + '/dimitri.png';
 const currentUrl = window.location.href; // URL of the current page

        const copyArticleLink = () => {
        navigator.clipboard.writeText(window.location.href);
        alert('Link copied to clipboard!');
    };

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: '[Hello world]',
                url: window.location.href,
                // You can add more details here as per your requirement
            }).catch((error) => console.log('Error sharing', error));
        } else {
            copyArticleLink();
        }
    };

    return (
        <div>
            <Navblock />
            <div className="max-w-4xl mx-auto p-6">
                <Helmet>
                    <title>[Hello world]</title>
                    <meta name="description" content="we are live" />
                    {/* Other relevant meta tags */}
                </Helmet>

                {/* Thumbnail Image as Banner */}


                {/* Author Section */}
              
                {/* Author and Share Icons Section */}
                <div className="flex justify-between items-center mt-8">
                    {/* Author Section */}
                    <div className="flex items-center">
                        <img src={authorImageUrl} alt="Author" className="rounded-full w-10 h-10 mr-4" />
                        <div>
                            <p className="font-semibold flex justify-start text-left">Dimitri van Iersel</p>
                            <p className="text-sm text-gray-600">Gepubliceerd: Jan 14, 2024</p>
                        </div>
                    </div>

                    {/* Share Icons */}
                    <div className="flex items-center hidden md:block">
                        <a href={`https://twitter.com/intent/tweet?url=${currentUrl}`} target="_blank" rel="noopener noreferrer" className="mx-1">
                            <FontAwesomeIcon icon={faXTwitter} className="text-blue-500 bg-white rounded-full p-2" />
                        </a>
                        <a href={`whatsapp://send?text=${currentUrl}`} data-action="share/whatsapp/share" target="_blank" rel="noopener noreferrer" className="mx-1">
                            <FontAwesomeIcon icon={faWhatsapp} className="text-green-500 bg-white rounded-full p-2" />
                        </a>
                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`} target="_blank" rel="noopener noreferrer" className="mx-1">
                            <FontAwesomeIcon icon={faFacebook} className="text-blue-600 bg-white rounded-full p-2" />
                        </a>
                        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}`} target="_blank" rel="noopener noreferrer" className="mx-1">
                            <FontAwesomeIcon icon={faLinkedin} className="text-blue-700 bg-white rounded-full p-2" />
                        </a>
                        <button onClick={copyArticleLink} className="mx-1">
                            <FontAwesomeIcon
icon={faCopy} className="text-gray-600 bg-white rounded-full p-2" />
</button>
</div>

                    {/* Responsive Share Button */}
                    <div className="sm:hidden">
                        <button onClick={handleShare} className="text-white bg-blue-500 rounded-full p-2">
                            <FontAwesomeIcon icon={faShareAlt} />
                        </button>
                    </div>
                </div>


                {/* Mobile Share Options (Visible when showMobileShare is true) */}

            <hr className="my-4 border-t border-gray-300" />
                {/* Article Content */}


                <img 
                    src={thumbnailUrl} 
                    alt="Banner" 
                    className="w-full h-auto object-cover rounded-lg mb-4"
                />
                <div>
                    <h1 className="text-3xl font-bold mb-8 flex justify-start text-left">[Hello world]</h1>
                    </div>
                    <div>   

                   <h2 className="text-2xl font-bold mb-4 flex justify-start text-left">We are live</h2>
                   <p className="text-lg mb-4 flex justify-start text-left">Welkom op onze website. Hier kan uw al het laatste nieuws en informatie vinden over Lizz Innovations en al haar bezigheden en ontwikkelingen.</p>

                   <p className="text-lg mb-4 flex justify-start text-left">Wij kijken er naar uit om binnenkort meer met jullie te delen.</p>

                   <p className="text-lg mb-4 flex justify-start text-left">Vergeet ons niet te volgen op sociale media.</p>

                   <p className="text-lg mb-4 flex justify-start text-left">Heeft u vragen of enige bijdragen? Stuur ons een mail.</p>


                    {/* ... rest of the article content ... */}
                </div>

                {/* Links and Call to Action */}
                <div className="mt-8">
                    <a href="https://lizzinnovations.com/" className="text-blue-600 hover:underline">Lizz Innovations website</a>
                    <a href="https://www.linkedin.com/company/99557072/admin/feed/posts/?feedType=following" className="text-blue-600 hover:underline ml-4">LinkedIn</a>
                </div>
            </div>
        </div>
    );
};

export default FullArticle2Page;