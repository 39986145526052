import React from 'react';
import { Link } from 'react-router-dom';

const Articletop = ({ articles }) => {
    return (
       
        <div className="max-w-6xl mx-auto p-6 rounded-2xl">
            {articles.map((article, index) => (
                <div key={index} className="flex flex-col md:flex-row mb-4 rounded-2xl p-4 items-stretch">
                    {/* Thumbnail Image */}
                      <Link to="/FullArticle" className="w-full mt-6 md:w-2/3 h-auto object-cover ml-2 mb-4 md:mb-0 md:mr-4">
                    <img 
                        src={article.thumbnail} 
                        alt="Thumbnail" 
                        className="rounded-2xl "
                    />
                 </Link>
                    {/* Text Content and Button */}
                    <div className="w-full md:w-1/3 md:ml-4 flex flex-col justify-between pl-4">
                        <div>
                          <Link to="/FullArticle">
                            <h2 className="text-2xl font-bold mt-4 text-left hover:text-blue-600">{article.title}</h2>
                           </Link>
                            <p className="text-mediumGray pb-2 pt-2 text-left">{article.subtext}</p>
                            <div className="flex items-center mt-2">
                                <img 
                                    src={article.authorImage} 
                                    alt="Author"
                                    className="rounded-full w-8 h-8 mr-2"
                                />
                                <span className="mr-2">{article.author}</span>
                                <span className="text-gray-500">{article.date}</span>
                            </div>
                        </div>

                        {/* Button */}
                        <div className="flex justify-start mt-4">
                            <Link className="bg-customBlue text-customGray py-3 px-8 rounded-2xl text-2xl hover:bg-sky-700" to="/FullArticle">Read article</Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
      
    );
};

export default Articletop;
