import React, { useEffect } from 'react';
import './App.css';
import useIntersectionObserver from './useIntersectionObserver';

function MissionGoals() {
    const [ref, isVisible] = useIntersectionObserver();

    useEffect(() => {
        if (isVisible) {
            const elements = document.querySelectorAll('.slide-fade-in');
            elements.forEach((element) => {
                element.classList.add('slide-in-animation');
            });
        }
    }, [isVisible]);



  return (
 <div className="font-interfont bg-mediumGray3 pb-8 pt-10 ">
<div className='container text-center md:text-left text-5xl mb-4 pt-8 mx-auto flex flex-col md:flex-row items-center justify-center md:justify-start'>
    <h1 className='pl-7 pt-6 text-white mb-4 md:mb-0'>Mission & Goals</h1>
    <img
        src={process.env.PUBLIC_URL + '/bladicon.png'}
        alt="Placeholder"
        className="ml-0 md:ml-2 text-5xl w-24"
    />
</div>
      <div className="container mx-auto my-16 flex flex-col md:flex-row md:space-x-8">
        <div ref={ref} className={`md:w-1/2 p-8 mb-8 md:mb-0 flex flex-col md:text-left order-2 md:order-1 lg:fade-in ${isVisible ? 'visible' : ''}`}>
          <div className="flex-grow">
            <h2 className="text-3xl font-bold mb-4 text-center md:text-left text-white">Missie</h2>
            <p className="text-gray-300 mb-4 text-center md:text-left">
Onze missie is om de grenzen te verleggen op het gebied van technologie en software. Op basis van innovativiteit, gebruiksvriendelijkheid en kwaliteit streven wij naar nieuwe manieren om technologische innovaties te creëren. 
            </p>
                    <h2 className="text-3xl font-bold mb-4 text-center md:text-left text-white mt-8">Visie</h2>
            <p className="text-gray-300 mb-4 text-center md:text-left">
Onze visie is om een wereld te creëren waarin innovatieve, personaliseerbare en interactieve technologieën een vast onderdeel wordt van de samenleving. We streven ernaar om de grenzen op het gebied van technologie en software te verleggen.  
            </p>    
          </div>

        </div>
     <div className="md:w-1/2 order-1 md:order-2 flex justify-center md:justify-start md:mr-0">
                    <div className="w-full max-h-96 overflow-hidden">
                        <img
                            src={process.env.PUBLIC_URL + '/zwart_wit.jpg'}
                            alt="Placeholder"
                            className={`w-full h-full object-cover lg:fade-in ${isVisible ? 'visible' : ''}`}
                        />
                    </div>
      </div>
      </div>
            <div className="container mx-auto my-16 flex flex-col md:flex-row md:space-x-8">

      </div>
    </div>
    
  );
}

export default MissionGoals;