import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router
import FAQSection from './FAQSection';
import BlogPage from './BlogPage';

const Faqbutton = () => {
    // Example blog posts data
    const posts = [
        { id: 1, title: 'Not what you were looking for?', summary: 'can\'t find an awnser to your question check here' },
        // ... other posts
    ];

    return (
        <div className='mt-10 pt-10 pb-6 bg-mediumGray text-white'>
            {posts.map(post => (
                <Link key={post.id} to={`/blog/${post.id}`}>
                    <div className="p-4 hover:bg-mediumGray">
                        <h2 className="text-4xl font-bold">{post.title}</h2>
                        <p>{post.summary}</p>
                    </div>
                </Link>
            ))}
                  <div className="flex justify-center mt-2 mb-8">
        <Link className="bg-customBlue text-customGray py-3 px-8 rounded-2xl sm:text-2xl mt-6 md:self-auto items-center text-2xl hover:bg-sky-700" to="/blog">View FAQ</Link>
      </div>
        </div>
    );
};

export default Faqbutton;