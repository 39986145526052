import React, { useState } from 'react';

const faqs = [
    { id: 1, question: 'How can I contact Lizz Innovations?', answer: 'You can contact us using email.' },
    { id: 2, question: 'What are your operating hours?', answer: 'We are open from 9am to 22pm.' },
    { id: 3, question: 'How can I get updates?', answer: 'Keep an eye on our website and socials.' },
    { id: 4, question: 'Do you offer online services?', answer: 'Yes, we offer a variety of online services.' },
    { id: 5, question: 'How can I pre-order?', answer: 'Currently we do not yet accept pre-orders, but keep an eye out on our website and socials.' },
];
 const imageURL = process.env.PUBLIC_URL + '/P1000498.JPG'; // URL to the image

const FAQAccordion = () => {
    const [openId, setOpenId] = useState(null);

    const toggleOpen = (id) => {
        if (openId === id) {
            setOpenId(null); // Close the currently open item
        } else {
            setOpenId(id); // Open the new item
        }
    };

    const FAQSchemaMarkup = () => {
        const structuredData = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": faqs.map(faq => ({
                "@type": "Question",
                "name": faq.question,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": faq.answer
                }
            }))
        };

        return (
            <script type="application/ld+json">
                {JSON.stringify(structuredData)}
            </script>
        );
    };


    return (
        
                <div>
            {/* Title block with background image */}
            <div 
                className="w-full bg-cover bg-center py-10" 
            >
                <div className="text-center text-black">
                    <h1 className="text-3xl font-bold mt-36">FAQs</h1>
                    <p className="text-xl mt-2">Everything you need to know</p>
                </div>
            </div>

        <div className="max-w-md mx-auto mt-4 mb-36">
            {faqs.map(faq => (
                <div key={faq.id}>
                    <div
                        className="cursor-pointer px-4 py-3 bg-gray-100 rounded-t-lg flex justify-between items-center"
                        onClick={() => toggleOpen(faq.id)}
                    >
                        <h2 className="text-lg font-semibold">{faq.question}</h2>
                        <span className="text-xl font-bold text-blue-600 ">{openId === faq.id ? '−' : '+'}</span>
                    </div>
                    {openId === faq.id && (
                        <div className="p-4 bg-white rounded-b-lg border border-t-0 border-gray-200">
                            <p>{faq.answer}</p>
                        </div>
                    )}
                </div>
            ))}
        </div>
        <FAQSchemaMarkup />
        </div>
    );
};

export default FAQAccordion;