import React from 'react';
import Slideshow from './Slideshow';
import Slideshowtwo from './Slideshowtwo';
import Teampic from './Teamspic';
import BusinessCard from './BusinessCard';
import Blockblack from './Blokblack';
import Lucas from './Lucas';
import Omar from './Omar';
import Stefan from './Stefan';
import MissionGoals from './MissionGoals';

function AboutPage() {
  return (
    <div>
 <Slideshowtwo />
 <Teampic />
 <BusinessCard />
 <Blockblack />
 <Lucas />
 <Omar />
 <Stefan />
 <MissionGoals />
    </div>
  );
}

export default AboutPage;