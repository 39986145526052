import React from 'react';
import useIntersectionObserver from './useIntersectionObserver';
import './App.css';

function YopieSop() {
  return (
    <div>
      Hello, this is testcomp!
    </div>
  );
}

export default YopieSop;