import React from 'react';
import Slideshow from './Slideshow';
import Slideshowtwo from './Slideshowtwo';
import Teampic from './Teamspic';
import BusinessCard from './BusinessCard';
import Blockblack from './Blokblack';
import Lucas from './Lucas';
import Omar from './Omar';
import Stefan from './Stefan';
import MissionGoals from './MissionGoals';
import Bjgrad from './Bggrad';
import Navblock from './Navblock';
import Bottomschermen from './Bottomschermen';

function Productpage() {
  return (
    <div>
      <Navblock />
 <Bjgrad />
 <Bottomschermen />

    </div>
  );
}

export default Productpage;