import React from 'react';

const Bottomschermen = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-mediumGray to-gray-500 justify-center items-center mx-auto w-full flex flex-col md:flex-row">
      {/* Content van je pagina hier */}
      <div className="w-full max-w-2xl text-left p-4 pl-4"> {/* Max width kan aangepast worden naar wens */}
        <h1 className="text-5xl text-white font-bold font-interfont pl-32">Lizzard One</h1>
        <h1 className="text-2xl text-whitesub mb-4 font-interfont pl-32">Lizz Innovation</h1>
        <h2 className="text-lg text-whitesub mt-2 mb-8 font-interfont pl-32">
          Binnenkort, meer tot snel!
        </h2>
                    <div className="flex pl-32 m-auto">
<button onClick={() => window.location = 'mailto:info@lizzinnovations.com'}className="bg-customBlue text-customGray py-3 px-8 rounded-2xl self-center md:self-auto text-2xl flex items-center hover:bg-sky-700">
  Meld aan
</button>
            </div>
        </div>

      <div className="w-full flex justify-center">
      <img src="/bottomscherm.png" alt="Team" className="w-9/12 h-auto" />
    </div>
            
      {/* Andere componenten en elementen */}
    </div>
  );
};

export default Bottomschermen;