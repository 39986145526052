import React from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook, faLinkedin, faTiktok, faInstagram, faYoutube, faPinterest} from '@fortawesome/free-brands-svg-icons'
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

function ContentSection() {


  return (
 <div className="font-interfont">
      <div className="container mx-auto my-16 flex flex-col md:flex-row md:space-x-8">
        <div className="md:w-1/2 p-8 mb-8 md:mb-0 flex flex-col md:text-left order-2 md:order-1 fades-in">
          <div className="flex-grow">
            <h2 className="text-3xl font-bold mb-4 text-center md:text-left">Lizz Innovations</h2>
            <p className="text-gray-700 mb-4 text-center md:text-left lg:mb-28">
Lizz Innovations, hét innovatieve technologie- en softwarebedrijf van de toekomst. Ons eerste product is momenteel volop in ontwikkeling. Met dit product verleggen wij de grenzen van decoratie en techniek voor iedere woon- en werkruimte. We realiseren deze visie met het oog ten alle tijden gericht op gebruiksvriendelijke, innovatieve en kwalitatieve producten. 
            </p>
          </div>
          <div className="flex flex-col lg:flex-row md:items-start sm:items-center mt-auto">
            <div className="flex justify-center space-x-4 md:my-2 md:mb-6 text-4xl my-6">
<a href="https//facebook.com"> <FontAwesomeIcon icon={faFacebook} /></a> 
<a href="https://instagram.com/lizzinnovations?igshid=YzAwZjE1ZTI0Zg%3D%3D&utm_source=qr"> <FontAwesomeIcon icon={faInstagram} /></a> 
<a href="https//tiktok.com"> <FontAwesomeIcon icon={faTiktok} /></a> 
<a href="https://www.linkedin.com/company/lizzinnovations/about/"> <FontAwesomeIcon icon={faLinkedin} /></a> 
<a href="https//youtube.com"> <FontAwesomeIcon icon={faYoutube} /></a> 
<a href="https//pinterest.com"> <FontAwesomeIcon icon={faPinterest} /></a> 
            </div>
            <div className="flex justify-center lg:ml-4">
<button onClick={() => window.location = 'mailto:info@lizzinnovations.com'}className="bg-customBlue text-customGray py-3 px-8 rounded-2xl self-center md:self-auto text-2xl flex items-center hover:bg-sky-700">
  Contact
</button>
            </div>
          </div>
        </div>
      <div className="md:w-1/2 order-1 md:order-2 flex justify-center md:justify-start md:mr-0">
        <div className="w-full max-h-96 overflow-hidden">
          <img
            src={process.env.PUBLIC_URL + '/groepsfoto.png'}
            alt="Placeholder"
            className="w-full h-full object-cover slide-fade-in"
          />
        </div>
      </div>
      </div>
    </div>
  );
}

export default ContentSection;