import React, { useState } from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';
import ContactPage from './ContactPage';
import Productpage from './Productpage';
import { NavLink } from 'react-router-dom';
import BlogPage from './BlogPage';
import BloggoodPage from './BloggoodPage';
import BloggiePage from './BloggiePage';



function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="bg-transparent p-4 absolute top-0 left-0 right-0 z-10 flex-row mx-auto">
  <div className="w-full mx-auto font-interfont">
        
        <div className="flex justify-between items-center z-30 relative">
          <div className="flex-shrink-0 ml-4 mr-20">
             <a className="text-white" href="/"><img src={process.env.PUBLIC_URL + '/logoicon2.png'} alt="Logo" className="h-10" /></a>
          </div>
          <div className="hidden md:flex space-x-8 text-xl justify-center mx-auto">
            <a className="text-white ml-0" href="/">Homepage</a>
<Link className="text-white" to="/about">About</Link>
<Link className="text-white" to="/product">Product</Link>
<Link className="text-white" to="/blog">FAQ</Link>
<Link className="text-white" to="/bloggie">News</Link>
          </div>
  <div className="flex items-center">
    <a href="#" className="flex items-center text-white mr-4">
<button onClick={() => window.location = 'mailto:info@lizzinnovations.com'}className="bg-customBlue text-customGray py-3 px-8 rounded-2xl self-center md:self-auto text-xl flex items-center hover:bg-sky-700 hidden md:block">
  Message us
</button>
    </a>
    <div className="md:hidden">
      <div className={`container ${isMenuOpen ? 'change' : ''}`} onClick={toggleMenu}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
    </div>
  </div>
</div>
      <div className={`menu ${isMenuOpen ? 'open' : ''} md:hidden`}>
        {/* When each Link is clicked, close the menu */}
        <Link to="/" className="block text-white mb-2 pl-4 mt-20 text-2xl font-medium" onClick={closeMenu}>Homepage</Link>
        <Link to="/about" className="block text-white mb-2 pl-4 mt-5 text-2xl font-medium" onClick={closeMenu}>About</Link>
        <Link to="/product" className="block text-white mb-2 pl-4 mt-5 text-2xl font-medium" onClick={closeMenu}>Product</Link>
        <Link to="/blog" className="block text-white mb-2 pl-4 mt-5 text-2xl font-medium" onClick={closeMenu}>FAQ</Link>
        <Link to="/bloggie" className="block text-white mb-2 pl-4 mt-5 text-2xl font-medium" onClick={closeMenu}>News</Link>
      </div>
      </div>
    </nav>
  );
}

export default Navbar;