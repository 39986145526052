import logo from './logo.svg';
import './App.css';
import YopieSop from './YopieSop';
import Navbar from './Navbar';
import Slideshow from './Slideshow';
import ContentSection from './ContentSection';
import TeamSection from './TeamSection';
import Eco from './eco';
import LizzardoneText from './LizzardoneText';
import {} from '@fortawesome/free-brands-svg-icons'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContactPage from './ContactPage'; 
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import AboutPage from './AboutPage'; 
import Productpage from './Productpage';
import BlogPage from './BlogPage';
import { NavLink } from 'react-router-dom';
import BloggiePage from './BloggiePage';
import FullArticlePage from './FullArticlePage';
import FullArticle2Page from './FullArticle2Page';
import FullArticle3Page from './FullArticle3Page';

ReactGA.initialize('G-BCL2LXMWBJ');

function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null; // This component doesn't render anything
}




function App() {


  useEffect(() => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
      navigator.serviceWorker.register('/serviceWorker.js').then(function(registration) {
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
      }, function(err) {
        console.log('ServiceWorker registration failed: ', err);
      }).catch(function(err) {
        console.log(err)
      });
    });
  } else {
    console.log('Service worker is not supported in this browser');
  }
}, []);


useEffect(() => {
  document.title = "Lizz Innovations"; // Set the title of the document

  // Change the favicon
  const link = document.querySelector("link[rel~='icon']");
  if (link) {
    link.href = 'favicon.ico'; // Replace 'favicon.ico' with the path to your new favicon
  }
}, []);

  return (
    <Router>
       <PageTracker />
      <div className="App bg-customGray">
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
              <Slideshow />
              <div className="container mx-auto my-8">
                {/* ...other content */}
              </div>
              <ContentSection />
              <TeamSection />
              <LizzardoneText />
              <Eco />
            </>
          } />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<AboutPage />} />
                    <Route path="/blog" element={<BlogPage />} />
                    <Route path="/product" element={<Productpage />} />
                                        <Route path="/Bloggie" element={<BloggiePage />} />
                                                                                <Route path="/FullArticle" element={<FullArticlePage />} />
                                                                                <Route path="/FullArticle2" element={<FullArticle2Page />} />
                                                                                                                                                                <Route path="/FullArticle3" element={<FullArticle3Page />} />
          {/* ...other routes */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;