import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Navblock from './Navblock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faTiktok, faInstagram, faWhatsapp, faPinterest, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCopy, faShareAlt } from '@fortawesome/free-solid-svg-icons';


const FullArticlePage = () => {
        const [showMobileShare, setShowMobileShare] = useState(false);
    const thumbnailUrl = process.env.PUBLIC_URL + '/banner.png'; 
    const authorImageUrl = process.env.PUBLIC_URL + '/lucas.png';
 const currentUrl = window.location.href; // URL of the current page

        const copyArticleLink = () => {
        navigator.clipboard.writeText(window.location.href);
        alert('Link copied to clipboard!');
    };

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Lizz Innovations: Transformeer Jouw Woon- en Werkruimte',
                url: window.location.href,
                // You can add more details here as per your requirement
            }).catch((error) => console.log('Error sharing', error));
        } else {
            copyArticleLink();
        }
    };

    return (
        <div>
            <Navblock />
            <div className="max-w-4xl mx-auto p-6">
                <Helmet>
                    <title>Lizz Innovations: Het technologiebedrijf van de toekomst</title>
                    <meta name="description" content="Wij van technologiebedrijf Lizz Innovations trekken momenteel aan het voortouw op het gebied van slimme decoratie." />
                    {/* Other relevant meta tags */}
                </Helmet>

                {/* Thumbnail Image as Banner */}


                {/* Author Section */}
              
                {/* Author and Share Icons Section */}
                <div className="flex justify-between items-center mt-8">
                    {/* Author Section */}
                    <div className="flex items-center">
                        <img src={authorImageUrl} alt="Author" className="rounded-full w-10 h-10 mr-4" />
                        <div>
                            <p className="font-semibold flex justify-start text-left">Lucas Bogaart</p>
                            <p className="text-sm text-gray-600">Gepubliceerd: Jan 15, 2024</p>
                        </div>
                    </div>

                    {/* Share Icons */}
                    <div className="flex items-center hidden md:block">
                        <a href={`https://twitter.com/intent/tweet?url=${currentUrl}`} target="_blank" rel="noopener noreferrer" className="mx-1">
                            <FontAwesomeIcon icon={faXTwitter} className="text-blue-500 bg-white rounded-full p-2" />
                        </a>
                        <a href={`whatsapp://send?text=${currentUrl}`} data-action="share/whatsapp/share" target="_blank" rel="noopener noreferrer" className="mx-1">
                            <FontAwesomeIcon icon={faWhatsapp} className="text-green-500 bg-white rounded-full p-2" />
                        </a>
                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`} target="_blank" rel="noopener noreferrer" className="mx-1">
                            <FontAwesomeIcon icon={faFacebook} className="text-blue-600 bg-white rounded-full p-2" />
                        </a>
                        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}`} target="_blank" rel="noopener noreferrer" className="mx-1">
                            <FontAwesomeIcon icon={faLinkedin} className="text-blue-700 bg-white rounded-full p-2" />
                        </a>
                        <button onClick={copyArticleLink} className="mx-1">
                            <FontAwesomeIcon
icon={faCopy} className="text-gray-600 bg-white rounded-full p-2" />
</button>
</div>

                    {/* Responsive Share Button */}
                    <div className="sm:hidden">
                        <button onClick={handleShare} className="text-white bg-blue-500 rounded-full p-2">
                            <FontAwesomeIcon icon={faShareAlt} />
                        </button>
                    </div>
                </div>


                {/* Mobile Share Options (Visible when showMobileShare is true) */}

            <hr className="my-4 border-t border-gray-300" />
                {/* Article Content */}


                <img 
                    src={thumbnailUrl} 
                    alt="Banner" 
                    className="w-full h-auto object-cover rounded-lg mb-4"
                />
                <div>
                    <h1 className="text-3xl font-bold mb-8 flex justify-start text-left">Lizz Innovations: Het technologiebedrijf van de toekomst</h1>
                    </div>
                    <div>
                        
                    <p className="text-lg mb-8 flex justify-start text-left">Wij van technologiebedrijf Lizz Innovations trekken momenteel aan het voortouw op het gebied van slimme decoratie. Ons team, dat bestaat uit vier gemotiveerde studenten, is momenteel fulltime bezig met het verleggen van grenzen op het gebied van decoratie en technologie. De toekomst van woon- en werkruimten is hier, waar gebruiksvriendelijkheid, innovatie en topkwaliteit centraal staan. 

                    </p>

                   <h2 className="text-2xl font-bold mb-4 flex justify-start text-left">Eerste product “Lizzard One”</h2>
                   <p className="text-lg mb-8 flex justify-start text-left">Ons eerste product, codenaam lizzard_one, is momenteel nog volop in ontwikkeling. Het product is een interactief scherm dat een revolutie zal betekenen voor bedrijven met kantoren, huishoudens, architecten en zelfs gamers. Het product is op een oneindig aantal manieren te gebruiken in vele verschillende omgevingen. We zullen meer updates vrijgeven wanneer de ontwikkelingsfase zich naar de volgende stap buigt. </p>

                   <h2 className="text-2xl font-bold mb-4 flex justify-start text-left">De toekomst van de toekomst</h2>
                   <p className="text-lg mb-4 flex justify-start text-left">De testfase van ons product is momenteel nog volop aan de gang. Wij van Lizz Innovations kijken er echter erg naar uit om meer informatie met jullie te delen over lizzard_one. </p>

                   <p className="text-lg mb-4 flex justify-start text-left">Lizz Innovations is voor ons niet alleen een technologiebedrijf. Het is een revolutie die ons perspectief op het gebied van decoratie en technologie doet veranderen. Op de website en sociale media van ons technologiebedrijf zullen in de komende periode meer updates verschijnen.  </p>

                   <p className="text-lg mb-4 flex justify-start text-left">Heeft u vragen of enige bijdragen? Stuur ons een mail of bel ons en kijk mee met de technologische veranderingen van de toekomst.</p>


                    {/* ... rest of the article content ... */}
                </div>

                {/* Links and Call to Action */}
                <div className="mt-8">
                    <a href="https://lizzinnovations.com/" className="text-blue-600 hover:underline">Lizz Innovations website</a>
                    <a href="https://www.linkedin.com/company/99557072/admin/feed/posts/?feedType=following" className="text-blue-600 hover:underline ml-4">LinkedIn</a>
                </div>
            </div>
        </div>
    );
};

export default FullArticlePage;