import React, { useState, useEffect } from 'react';
import './slideshow.css';
import useIntersectionObserver from './useIntersectionObserver';
import './App.css';

function Slideshow() {
  const [isAnimated, setIsAnimated] = useState(false);

  const animateSlideshow = () => {
    setIsAnimated(true);

    window.removeEventListener('scroll', animateSlideshow);
  };

  useEffect(() => {

    const timer = setTimeout(animateSlideshow, 1000);

    window.addEventListener('scroll', animateSlideshow);
    
    return () => {
      clearTimeout(timer);
      window.removeEventListener('scroll', animateSlideshow);
    };
  }, []);


      const [ref, isVisible] = useIntersectionObserver();
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    'https://lizzinnovations.com/scherm1a.png',
    'https://lizzinnovations.com/scherm1b.png',
    'https://lizzinnovations.com/scherm1c.png'
    
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);  
    return () => clearInterval(timer); 
  }, []);

  return (
    <div className={`slideshow ${isAnimated ? 'animate' : ''} bg-black`}>
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slide ${index === currentSlide ? 'active' : ''}`}
          style={{ backgroundImage: `url(${slide})` }}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="absolute inset-0 flex flex-col justify-center items-center">
            <h1 className="text-5xl text-white font-bold slide2-fade-in font-interfont">Welcome</h1>
            <h2 className="text-lg text-white mt-2 slide3-fade-in font-interfont">to the next step in technological innovation</h2>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Slideshow;

